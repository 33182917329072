import dayjs, { ManipulateType } from "dayjs";

interface RangeOfDatesProps {
  startDate: Date;
  endDate: Date;
  amount: number;
  unit: ManipulateType;
}

export const generateRangeOfDates = ({
  startDate,
  endDate,
  amount,
  unit,
}: RangeOfDatesProps) => {
  const range = [];

  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    currentDate = currentDate.add(amount, unit);
    range.push(currentDate.format());
  }

  return range;
};
