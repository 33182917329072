import smartXAbi from "./abi.json";
import depositAbi from "./abiDeposit.json";
import allocateAbi from "./abiAllocate.json";

export const SmartXLogicAbi = smartXAbi;
export const ABIDeposit = depositAbi;
export const ABIAllocate = allocateAbi;

export * from "./shared";
export * from "./validations";
export * from "./sidebar";
export * from "./deposit";
export * from "./referral";
export * from "./finance";
export * from "./faq";
