import { DepositHistory } from "components/DepositHistory";
import { Box } from "components/shared/Box";
import { DepositForm } from "components/DepositForm";
// import { DepositInstruction } from "components/DepositInstruction";

export const FinanceDepositView = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
      <Box className="col-span-1 md:col-span-4">
        <DepositForm />
      </Box>
      {/* <Box className="col-span-1 md:col-span-2">
        <DepositInstruction />
      </Box> */}
      {/* <Box className="col-span-1 md:col-span-4">
        <DepositHistory />
      </Box> */}
    </div>
  );
};
