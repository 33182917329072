import {
  HomeIcon,
  FinanceIcon,
  ReferralIcon,
  SettingsIcon,
  FaqIcon,
} from "components/shared/Icons";
import { ROUTES } from "config/routes";

export const menuItems = [
  {
    icon: <HomeIcon />,
    title: "Dashboard",
    link: ROUTES.home,
  },
  {
    icon: <FinanceIcon />,
    title: "Finance",
    link: ROUTES.finance,
  },
  {
    icon: <ReferralIcon />,
    title: "Referral program",
    link: ROUTES.referral,
  },
  {
    icon: <SettingsIcon />,
    title: "Settings",
    link: ROUTES.settings,
  },
  {
    icon: <FaqIcon />,
    title: "FAQ",
    link: ROUTES.faq,
  },
];
