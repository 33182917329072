import { ViewContent } from "components/shared/ViewContent";
import { useQuery } from "@tanstack/react-query";
import { getTopReferrals } from "requests";

export const TopReferrals = () => {
  const {
    data,
    isLoading: isTopRefUsersLoading,
    isError,
  } = useQuery({
    queryKey: ["topReferrals"],
    queryFn: getTopReferrals,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h5 className="text-lGrey font-bold text-base">Top referrals</h5>
      </div>
      <ViewContent
        isError={isError}
        isLoading={isTopRefUsersLoading}
        data={data && data.data ? [data.data] : []}
      >
        {(data: any) => {
          return (
            <ul className="max-h-[330px] overflow-y-auto w-full">
              {data[0].map((topRefUser: any, i: number) => (
                <li
                  key={i}
                  className="py-[10px] border-b border-lightBlack grid grid-cols-3"
                >
                  <div>
                    <p className="text-sm text-lGrey font-semibold">
                      {topRefUser.login}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-sm text-lGrey font-semibold">
                      Level {topRefUser.tier}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-sm text-lGrey font-semibold mr-[6px] text-right">
                      $ {topRefUser.depositedAmount}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          );
        }}
      </ViewContent>
    </div>
  );
};
