import { PropsWithChildren, FC } from "react";
import { createPortal } from "react-dom";
import { usePortal } from "hooks";

interface PortalProps {
  id: string;
}

export const Portal: FC<PropsWithChildren<PortalProps>> = ({
  id,
  children,
}) => {
  const target = usePortal(id);
  return createPortal(
    children,
    // @ts-expect-error
    target
  );
};
