import classNames from "classnames";
import { SettingsIcon } from "components/shared/Icons";
import { useUserContext } from "context/user.context";
import { useAuth } from "hooks";
import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface NavbarProps {
  toggleSidebar: any;
}

export const Navbar: FC<NavbarProps> = ({ toggleSidebar }) => {
  const { handleSignout } = useAuth();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isSettingsPage = location.pathname === "/settings";

  const handleRedirectToSettings = () => navigate("/settings");

  return (
    <>
      <nav className="mt-5 md:mt-3 mb-5 md:mb-[30px] xl:h-[30px] flex items-center justify-between flex-col xl:flex-row">
        <div className="flex items-center justify-between w-full ">
          <div className="hidden xl:block"></div>

          <div className="block xl:hidden">
            <div className="flex">
              <div className="ml-5 max-w-[120px]">
                <h2 className="mb-[6px] text-sm text-white">
                  Hello, {user?.firstName}
                </h2>
                <p className="text-xs font-semibold text-grey text-ellipsis overflow-hidden">
                  {user?.login}
                </p>
                <p onClick={handleSignout} className="mt-2 text-sm">
                  Logout
                </p>
              </div>
            </div>
          </div>

          <div className="flex">
            <div
              className={classNames(
                " [&>svg]:fill-[#EAECEF] p-2 cursor-pointer",
                isSettingsPage && "bg-main rounded-xl  [&>svg]:fill-lightBlack"
              )}
              onClick={handleRedirectToSettings}
            >
              <SettingsIcon />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
