import { useQuery } from "@tanstack/react-query";

import { getTransactions } from "requests";
import { ViewContent } from "components/shared/ViewContent";
import dayjs from "dayjs";

type TransactionType =
  | "deposit"
  | "withdrawal"
  | "profit_income"
  | "referral_income"
  | "binary_income";

const getTransactionType = (type: TransactionType) => {
  const types = {
    deposit: "Deposit",
    withdrawal: "Withdrawal",
    profit_income: "Profit Income",
    referral_income: "Referral Income",
    binary_income: "Binary Income",
  };

  return types[type];
};

const TableRow = ({ referralAccrual }: any) => {
  return (
    <div className="py-4 border-t border-lightBlack">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-10 md:gap-5">
        <div>
          <h3 className="text-lGrey font-semibold text-sm ">
            ${referralAccrual.amount}
          </h3>
        </div>
        <div>
          <h3 className="text-lGrey font-semibold text-sm">
            {dayjs(referralAccrual.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </h3>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-success font-semibold text-sm">
              {getTransactionType(referralAccrual.type)}
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-success font-semibold text-sm">Completed</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FinaceDetailedTable = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["userTransactionsDefaultView"],
    queryFn: getTransactions,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h3 className="text-lGrey font-semibold mb-5 md:mb-0">
          Detailed table{" "}
        </h3>
      </div>
      <div>
        <div className="hidden md:grid grid-cols-4 pb-5">
          <div className="text-grey font-semibold text-xs">Amount</div>
          <div className="text-grey font-semibold text-xs">Date</div>
          <div className="text-grey font-semibold text-xs">Type</div>
          <div className="text-grey font-semibold text-xs">Status</div>
        </div>
        <div className="max-h-[536px] overflow-y-auto pr-5">
          <ViewContent
            isError={isError}
            isLoading={isLoading}
            data={data && data.data ? [data.data] : []}
          >
            {(data: any) =>
              data[0].map((referralAccrual: any) => (
                <TableRow
                  key={referralAccrual.id}
                  referralAccrual={referralAccrual}
                />
              ))
            }
          </ViewContent>
        </div>
      </div>
    </div>
  );
};
