import {
  InstagramFaqIcon,
  TelegramFaqIcon,
  WtsFaqIcon,
  MailFaqIcon,
} from "components/shared/Icons";

export const faqBlocks = [
  {
    title: "Is there a limitation on the depth of the binary structure?",
    body: (
      <p>Your income from the binary structure has no depth restrictions.</p>
    ),
  },
  {
    title: "Referral program",
    body: (
      <p>
        Depending on your TIER, you can receive up to 10% from the deposits of
        your direct partners and up to 10% from the turnover of your entire
        structure.
      </p>
    ),
  },
  {
    title: "Conditions for Binary TIER reward",
    body: (
      <div className="[&>*]:mt-5">
        <div className="!mt-0">
          <h4 className="m-1">Tier 1:</h4>
          <p className="ml-2">- Total binary turnover: less than $10,000</p>
          <p className="ml-2">- Income: 5% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 2:</h4>
          <p className="ml-2">- Total binary turnover: $10,000</p>
          <p className="ml-2">- Income: 6% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 3:</h4>
          <p className="ml-2">- Total binary turnover: $50,000</p>
          <p className="ml-2">- Income: 7% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 4:</h4>
          <p className="ml-2">- Total binary turnover: $100,000</p>
          <p className="ml-2">- Income: 8% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 5:</h4>
          <p className="ml-2">- Total binary turnover: $500,000</p>
          <p className="ml-2">- Income: 9% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 6:</h4>
          <p className="ml-2">- Total binary turnover: $1,000,000</p>
          <p className="ml-2">- Income: 10% from team turnover</p>
        </div>
      </div>
    ),
  },
  {
    title: "Conditions for DIRECT TIER reward",
    body: (
      <div className="[&>*]:mt-5">
        <div>
          <h4 className="ml-1">Tier 1:</h4>
          <p className="ml-2">- Personal deposit: less than $300</p>
          <p className="ml-2">- Income: 5% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 2:</h4>
          <p className="ml-2">- Total binary turnover: less than $500</p>
          <p className="ml-2">- Income: 6% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 3:</h4>
          <p className="ml-2">- Total binary turnover: less than $1000</p>
          <p className="ml-2">- Income: 7% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 4:</h4>
          <p className="ml-2">- Total binary turnover: less than $3000</p>
          <p> className="ml-2"- Income: 8% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 5:</h4>
          <p className="ml-2">- Total binary turnover: less than $5000</p>
          <p className="ml-2">- Income: 9% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 6:</h4>
          <p className="ml-2">- Total binary turnover: more than $5001</p>
          <p className="ml-2">- Income: 10% from direct partner's deposit.</p>
        </div>
      </div>
    ),
  },
  {
    title: "What % of partner deposits can I receive?",
    body: (
      <p>
        You can receive up to 10% from the turnover of your entire structure.
      </p>
    ),
  },
  {
    title: 'What is "Binary Turnover"?',
    body: (
      <p>
        This is the turnover of the weaker leg, summed up with each collapse of
        the binary cycle.
      </p>
    ),
  },
  {
    title: "How can I increase my TIER?",
    body: (
      <p>
        Your TIER is determined by the total binary turnover and the size of
        your personal deposit. Increase your binary turnover and deposit to
        raise your TIER level and your percentage of income.
      </p>
    ),
  },
  {
    title: "When is the bonus from a direct partner's deposit paid out?",
    body: (
      <p>
        A direct bonus from each investment made by a partner is paid out
        instantly.
      </p>
    ),
  },
];

export const faqBlocksContacts = [
  {
    title: "instagram",
    link: "/",
    icon: <InstagramFaqIcon />,
  },
  {
    title: "telegram",
    link: "/",
    icon: <TelegramFaqIcon />,
  },
  {
    title: "telegram bot",
    link: "/",
    icon: <TelegramFaqIcon />,
  },
  {
    title: "WhatsApp ",
    link: "/",
    icon: <WtsFaqIcon />,
  },
  {
    title: "Email",
    link: "/",
    icon: <MailFaqIcon />,
  },
];
