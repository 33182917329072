import { FC, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";

import { useToggle } from "hooks";
import { Nullable, ReferralTreeDataProps } from "types";
import { Modal } from "components/shared/Modal";
import { Portal } from "components/shared/Portal";
import { InfoIconRoot, InfoIconUser, MoreDots } from "components/shared/Icons";
import { getSearchReferralUser } from "requests";
import { DebouncedInput } from "components/shared/DebounceInput";
import { useFormContext } from "react-hook-form";
import { Loader } from "components/shared/Loader";

interface ReferralGraphTreeProps {
  tree: any;
  binaryStats: any;
  isReferralsLoadingUserStats: boolean;
}

const fillEmptyNode = () => ({
  id: null,
  email: "-",
  login: "-",
  firstName: "-",
  lastName: "-",
  leftChild: null,
  rightChild: null,
  hasMoreChildren: false,
});

const trimAndFillTree: any = (node: any, depth: any) => {
  if (!node || depth === 0) {
    return null;
  }

  const leftChild = trimAndFillTree(node.leftChild, depth - 1);
  const rightChild = trimAndFillTree(node.rightChild, depth - 1);

  return {
    ...node,
    leftChild: leftChild === null && depth > 1 ? fillEmptyNode() : leftChild,
    rightChild: rightChild === null && depth > 1 ? fillEmptyNode() : rightChild,
    hasMoreChildren: !!node.leftChild || !!node.rightChild,
  };
};

const fillToDepth: any = (node: any, targetDepth: any, currentDepth = 1) => {
  if (currentDepth >= targetDepth) {
    return node;
  }

  const leftChild = node.leftChild || fillEmptyNode();
  const rightChild = node.rightChild || fillEmptyNode();

  return {
    ...node,
    leftChild: fillToDepth(leftChild, targetDepth, currentDepth + 1),
    rightChild: fillToDepth(rightChild, targetDepth, currentDepth + 1),
    hasMoreChildren: !!node.leftChild || !!node.rightChild,
  };
};

const findNodeAndTrimTree: any = (node: any, selectedId: any, depth: any) => {
  if (!node) return null;

  if (node.id === selectedId) {
    return fillToDepth(trimAndFillTree(node, depth), depth);
  }

  const leftResult = findNodeAndTrimTree(node.leftChild, selectedId, depth);
  if (leftResult) {
    return leftResult;
  }

  return findNodeAndTrimTree(node.rightChild, selectedId, depth);
};

// REQUIRED RENDERING ON REFERRAL LEG
const renderTreeNode = (
  node: Nullable<ReferralTreeDataProps>,
  handleSelectReferral: (referralUser: ReferralTreeDataProps) => void,
  setSelectedTreeElement: any
) => {
  if (!node) {
    return (
      <TreeNode
        label={
          <div className="bg-[#131313] w-[182px] m-auto rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <p className="font-semibold text-[#fff] text-ellipsis overflow-hidden">
                -
              </p>
            </div>
            <p className="text-left text-[#646464] text-ellipsis overflow-hidden">
              -
            </p>
          </div>
        }
      />
    );
  }

  const renderTreeNextElements = () => {
    if (node.leftChild || node.rightChild) {
      return (
        <>
          {renderTreeNode(
            node.leftChild,
            handleSelectReferral,
            setSelectedTreeElement
          )}
          {renderTreeNode(
            node.rightChild,
            handleSelectReferral,
            setSelectedTreeElement
          )}
        </>
      );
    }

    if (node.hasMoreChildren) {
      return (
        <div className="flex items-center justify-center w-full">
          <MoreDots />
        </div>
      );
    }

    return null;
  };

  return (
    <TreeNode
      label={
        <div className="bg-[#131313] w-[150px] m-auto rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <p
              className={classNames(
                "font-semibold text-[#fff] text-ellipsis overflow-hidden",
                node.id && "cursor-pointer"
              )}
              onClick={() => node.id && setSelectedTreeElement(node)}
            >
              {node.login}
            </p>
            {node.id && (
              <div
                className={classNames(
                  "hover:[&>*]:!stroke-[#fff] hover:[&>svg]:[&>circle]:stroke-white",
                  node.id && "cursor-pointer"
                )}
                onClick={() => node.id && handleSelectReferral(node)}
              >
                <InfoIconUser />
              </div>
            )}
          </div>
          <p
            className={classNames(
              "text-left text-[#646464] text-ellipsis overflow-hidden",
              node.id && "cursor-pointer"
            )}
            onClick={() => node.id && setSelectedTreeElement(node)}
          >
            {node.email}
          </p>
        </div>
      }
    >
      {renderTreeNextElements()}
    </TreeNode>
  );
};

const OrganizationalTree = ({
  data,
  handleSelectReferral,
  setSelectedTreeElement,
  selectedTreeElement,
  topUser,
  binaryStats,
  isReferralsLoadingUserStats,
}: {
  data: ReferralTreeDataProps;
  handleSelectReferral: (referralUser: ReferralTreeDataProps) => void;
  setSelectedTreeElement: any;
  selectedTreeElement: any;
  topUser: any;
  binaryStats: any;
  isReferralsLoadingUserStats: boolean;
}) => {
  const { setValue } = useFormContext();

  return (
    <>
      <Tree
        lineWidth={"2px"}
        lineColor={"#FEE30A"}
        lineBorderRadius={"2px"}
        label={
          // root-tree-element - styles are in css file, since target is next sibling's ::before element from library
          <div
            className={classNames(
              "bg-main max-w-[182px] m-auto rounded-lg p-4 root-tree-element",
              selectedTreeElement && "mt-[240px]"
            )}
          >
            <div className="flex items-center justify-between mb-2">
              <p className="font-semibold text-[#191919] text-ellipsis overflow-hidden">
                {data?.login}
              </p>
              <div
                className="cursor-pointer"
                onClick={() => handleSelectReferral(data)}
              >
                <InfoIconRoot />
              </div>
            </div>
            <p className="text-left text-[#404040] text-ellipsis overflow-hidden">
              {data?.email}
            </p>
          </div>
        }
      >
        {selectedTreeElement && (
          <div className="flex items-center flex-col absolute -top-[305px] left-1/2 -translate-x-1/2">
            <div
              className={classNames(
                "w-[182px] m-auto rounded-lg p-4 root-tree-element",
                selectedTreeElement ? "bg-[#131313]" : "bg-main"
              )}
            >
              <div className="flex items-center justify-between mb-2">
                <p
                  className={classNames(
                    "font-semibold text-[#191919] text-ellipsis overflow-hidden cursor-pointer",
                    selectedTreeElement && "text-[#fff]"
                  )}
                  onClick={() => {
                    setValue("selectedTreeUser", null);
                    setSelectedTreeElement(null);
                  }}
                >
                  {topUser.login}
                </p>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleSelectReferral(topUser);
                  }}
                >
                  <InfoIconUser />
                </div>
              </div>
              <p
                className={classNames(
                  "text-left text-[#404040] text-ellipsis overflow-hidden cursor-pointer",
                  selectedTreeElement && "text-[#646464]"
                )}
                onClick={() => setSelectedTreeElement(null)}
              >
                {topUser.email}
              </p>
            </div>
            <div className="w-[2px] border h-[90px] bg-white" />
            <MoreDots />
            <div
              className="w-[2px] border h-[20px] bg-[#FEE30A]"
              key={"mainLine"}
            />
          </div>
        )}

        <div className="flex flex-col absolute md:w-[201px] left-[25%] top-[5px] md:top-5">
          {!isReferralsLoadingUserStats ? (
            <>
              <div className="text-sm flex flex-col md:flex-row items-center justify-between mb-2">
                <p>Active referrals:</p>{" "}
                <p className="text-main font-semibold">
                  {binaryStats?.leftLegStats?.activeReferrals}
                </p>{" "}
              </div>
              <div className="text-sm flex flex-col md:flex-row items-center justify-between">
                <p>Turnover structure:</p>{" "}
                <p className="text-main font-semibold">
                  ${binaryStats?.leftLegStats?.turnover}
                </p>{" "}
              </div>
            </>
          ) : (
            <Loader className="!bg-white" />
          )}
        </div>

        <div className="flex flex-col absolute md:w-[201px] right-[25%]  top-[5px] md:top-5">
          {!isReferralsLoadingUserStats ? (
            <>
              <div className="text-sm flex flex-col md:flex-row items-center justify-between mb-2">
                <p>Active referrals:</p>{" "}
                <p className="text-[#FF8126] font-semibold">
                  {binaryStats?.rightLegStats?.activeReferrals}
                </p>{" "}
              </div>
              <div className="text-sm flex flex-col md:flex-row items-center justify-between">
                <p>Turnover structure:</p>{" "}
                <p className="text-[#FF8126] font-semibold">
                  ${binaryStats?.rightLegStats?.turnover}
                </p>{" "}
              </div>
            </>
          ) : (
            <Loader className="!bg-white" />
          )}
        </div>
        {renderTreeNode(
          data.leftChild,
          handleSelectReferral,
          setSelectedTreeElement
        )}
        {renderTreeNode(
          data.rightChild,
          handleSelectReferral,
          setSelectedTreeElement
        )}
      </Tree>
    </>
  );
};

export const ReferralGraphTree: FC<ReferralGraphTreeProps> = ({
  tree,
  binaryStats,
  isReferralsLoadingUserStats,
}) => {
  const [selectedReferral, setSelectedReferral] = useState<any>(null);
  const [selectedTreeElement, setSelectedTreeElement] = useState<any>(null);
  const [showReferralModal, toggleReferralModal] = useToggle();

  const { getValues, setValue, watch } = useFormContext();

  watch("query");

  const {
    data: dataSearchReferralUsers,
    isLoading: isSuggestionsLoading,
    refetch,
  } = useQuery({
    queryKey: ["searchReferrals", getValues("query")],
    queryFn: getSearchReferralUser,
    enabled: getValues("query").length > 0,
  });

  const handleSelectReferral = (referralData: any) => {
    setSelectedReferral(referralData);
    toggleReferralModal();
  };

  const handleMoveUpTreeLevel = () => {
    console.log("move above");
    console.log(selectedTreeElement, "selected tree lement");
  };

  const trimmedTree = findNodeAndTrimTree(
    tree,
    selectedTreeElement ? selectedTreeElement.id : tree.id,
    4
  );

  const handleSelectUser = (node: any) => {
    console.log(node, "node??");
    setSelectedTreeElement(node);
    if (node) {
      setValue("selectedTreeUser", node.id);
    }
  };

  const renderSearchList = () => {
    const suggestionList: { id: number; login: string; email: string }[] =
      Object.values(dataSearchReferralUsers?.data ?? {});

    return (
      <div className="absolute bg-[#2D3037] overflow-y-auto h-[150px] w-full z-20">
        {isSuggestionsLoading && <Loader />}
        {!isSuggestionsLoading && suggestionList.length === 0 ? (
          <p className="mt-5 text-center">No data to show</p>
        ) : (
          suggestionList.map((suggestion) => {
            return (
              <div
                key={suggestion.id}
                onClick={() => {
                  setValue("query", "");
                  setSelectedTreeElement(suggestion);

                  setValue("selectedTreeUser", suggestion.id);
                }}
                className="p-4 cursor-pointer"
              >
                <p className="">{suggestion.login}</p>
                <p className="text-white">{suggestion.email}</p>
              </div>
            );
          })
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="relative max-w-[800px] w-full">
          <DebouncedInput
            type="text"
            className="py-[13px] px-4 w-full bg-[#2D3037] outline-none pr-[45px] rounded-[4px] placeholder:text-[#646464] text-[#F6EC9B]"
            placeholder="Search: Login or Email"
            value={getValues("query")}
            onChange={(value) => {
              setValue("query", value as string);
              refetch();
            }}
            debounce={1500}
          />
          <img
            className="absolute right-[16px] top-1/2 -translate-y-1/2 pointer-events-none"
            src="/icons/searchIcon.svg"
            alt="search icon"
          />
          {getValues("query").length > 0 && renderSearchList()}
        </div>
      </div>
      {/* TODO: add tree navigation */}
      {/* <div className="my-[40px] flex items-center justify-center flex-col">
        <p className="text-center text-[10px] text-[#404040] mb-2">
          Tree Navigation
        </p>
        <button
          type="button"
          className={classNames(
            "h-[40px] w-[40px] rounded-[4px] border border-lightBlack flex items-center justify-center cursor-pointer disabled:cursor-default",
            selectedTreeElement && "bg-[#2D3037]"
          )}
          disabled={!selectedTreeElement}
          onClick={handleMoveUpTreeLevel}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.646115 4.64538L4.49774 0.793762L4.50087 0.793762L4.64731 0.647315C4.84268 0.451953 5.16047 0.451953 5.35583 0.647315L9.35583 4.64732C9.4999 4.79139 9.54188 5.0039 9.46347 5.19208C9.38417 5.38241 9.20262 5.50314 9.00001 5.50314L1.00001 5.50314C0.79963 5.50314 0.615508 5.38158 0.536548 5.19208C0.459346 5.00679 0.500868 4.79285 0.645318 4.64619C0.645583 4.64592 0.645849 4.64565 0.646115 4.64538Z"
              stroke={selectedTreeElement ? "#666C72" : "#2D3037"}
            />
          </svg>
        </button>
      </div> */}
      <div className="w-full overflow-x-scroll mt-[40px]">
        <OrganizationalTree
          handleSelectReferral={handleSelectReferral}
          setSelectedTreeElement={handleSelectUser}
          data={trimmedTree}
          selectedTreeElement={selectedTreeElement}
          topUser={tree}
          binaryStats={binaryStats}
          isReferralsLoadingUserStats={isReferralsLoadingUserStats}
        />
      </div>
      <Portal id="infoReferralUser">
        <Modal
          className="bg-none !p-0"
          open={showReferralModal}
          handleClose={toggleReferralModal}
          showCloseButton={false}
        >
          {selectedReferral && (
            <div className="bg-[#212229] w-[320px] m-auto rounded-lg p-6">
              <div>
                <div className="flex items-center justify-between mb-2">
                  <p className="font-semibold text-[#fff] text-ellipsis overflow-hidden">
                    {selectedReferral.login}
                  </p>
                </div>
                <p className="text-left text-[#9B9B9B] text-ellipsis overflow-hidden text-xs ">
                  {selectedReferral.email}
                </p>
              </div>
              <div className="w-full h-[1px] bg-lightBlack my-5"></div>
              <div className="flex items-center">
                <div>
                  <p className="text-[#9B9B9B]">Tier</p>
                  <h2 className="text-main">{selectedReferral.tier}</h2>
                </div>
                <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
                <div>
                  <p className="text-[#9B9B9B]">Turnover</p>
                  <h2 className="text-white">${selectedReferral.turnover}</h2>
                </div>
                <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
                <div>
                  <p className="text-[#9B9B9B]">Balance</p>
                  <h2 className="text-white">${selectedReferral.balance}</h2>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </Portal>
    </>
  );
};
