import {
  ComputerIcon,
  FinanceFaqIcon,
  ReferralProgrammIcon,
} from "components/shared/Icons";
import { Accordion } from "components/shared/Accordion";
import { faqBlocks, faqBlocksContacts } from "const";
import { cloneElement } from "react";

export default function FaqPage() {
  return (
    <div>
      <div className="mb-9 text-white">
        <h2 className="text-6xl font-bold">FAQ</h2>
        <p className="md:text-2xl">Questions & answers</p>
      </div>
      <ul className="flex md:items-center flex-col md:flex-row md:[&>*]:ml-4 mb-[72px]">
        <li className="flex md:items-center md:justify-center mt-2 md:mt-0 !ml-0">
          <ComputerIcon />
          <span className="ml-3">General</span>
        </li>
        <li className="flex md:items-center md:justify-center mt-2 md:mt-0">
          <ReferralProgrammIcon />
          <span className="ml-3 text-lYellow">Referral Program</span>
        </li>
        <li className="flex md:items-center md:justify-center mt-2 md:mt-0">
          <FinanceFaqIcon />
          <span className="ml-3 text-lYellow">Finance</span>
        </li>
      </ul>
      <div className="max-w-[670px] [&>*]:mt-4 mb-[120px]">
        {faqBlocks.map((faqInfo) => {
          return (
            <Accordion
              key={faqInfo.title}
              text={faqInfo.body}
              title={faqInfo.title}
            />
          );
        })}
      </div>
      <div className="mb-12 relative">
        <h3 className="md:text-[60px] mb-10 font-bold text-white">Contacts</h3>
        <p className="text-lYellow">
          If you do not find the information you are looking for, please contact
          us:
        </p>
      </div>
      <div className="flex gap-6">
        {faqBlocksContacts.map((socialInfo) => {
          return (
            <a
              key={socialInfo.title}
              href={socialInfo.link}
              className="contactsFaqBlock flex flex-col border border-[#2C3138] rounded-2xl items-center h-[128px] w-[128px] md:h-[200px] md:w-[200px]"
            >
              {cloneElement(socialInfo.icon)}
              <h4 className="uppercase">{socialInfo.title}</h4>
            </a>
          );
        })}
      </div>
    </div>
  );
}
