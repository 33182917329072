import { useForm } from "react-hook-form";

import { useUserContext } from "context/user.context";
import { UserProps } from "types";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "components/shared/Input";

import { profileInfoValidation } from "const";
import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";

const Settings = () => {
  const { user, updateUser, isUserUpdating } = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProps>({
    defaultValues: {
      ...user,
      telegram: user?.telegram ?? "",
    } as UserProps,
    resolver: zodResolver(profileInfoValidation),
  });

  const onSubmit = async (formValues: UserProps) => {
    let userObj: any = {};

    if (formValues.telegram) {
      userObj.telegram = formValues.telegram;
    }
    updateUser({
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      login: formValues.login,
      ...userObj,
    });
  };

  return (
    <>
      <Box>
        <h4 className="text-lGrey font-bold mb-5">General</h4>
        {user?.email === null && (
          <p className="text-red mb-5 text-2xl">
            In order to continue, enter your email
          </p>
        )}
        <Box className="border-none bg-lightBlack">
          <form onSubmit={handleSubmit(onSubmit)} className="mt-10 md:mt-0">
            <Input
              type="text"
              id="firstName"
              register={register}
              placeholder="First Name"
              errorMessage={errors.firstName?.message}
              label="First Name"
              className="mb-5"
            />
            <Input
              type="text"
              id="lastName"
              register={register}
              placeholder="Last Name"
              errorMessage={errors.lastName?.message}
              className="mb-5"
              label="Last Name"
            />
            <div className="flex flex-col">
              <Input
                type="text"
                id="login"
                register={register}
                placeholder="Login"
                errorMessage={errors.login?.message}
                label="Login"
                className="mb-5"
              />
            </div>
            <div className="flex flex-col">
              <Input
                type="email"
                id="email"
                register={register}
                placeholder="E-email"
                errorMessage={errors.email?.message}
                description="Changes are available to you"
                label="E-mail"
              />
            </div>

            <Input
              type="text"
              id="telegram"
              register={register}
              placeholder="Telegram"
              errorMessage={errors.telegram?.message}
              description="Changes are available to you"
              label="Telegram"
            />
            <Button
              text="Save Changes"
              type="submit"
              className=" bg-main font-semibold text-sm "
              isLoading={isUserUpdating}
            />
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
