import { useUserContext } from "context/user.context";

import { Button } from "components/shared/Button";
import { InfoIcon } from "components/shared/Icons";

export const OverallBalance = () => {
  const { user, withdrawReferralProfit, isLoadingClaimingBinaryBonus } =
    useUserContext();

  const handleWithdrawProfitFromContract = () => {
    user &&
      withdrawReferralProfit(
        +user.claimedProfitAmount,
        +user.lastProfitClaimedAt,
        +user.referralBonusAmount
      );
  };

  const availableForWithdraw = user ? user.contractWithdrawalAmount : 0;

  return (
    <>
      <div className="flex flex-col justify-between md:flex-row">
        <div>
          <h5 className="mb-2 font-bold text-lGrey">Overall Balance</h5>
          <div className="flex items-start">
            <span className="mr-2 text-2xl font-semibold text-main">$</span>
            <span className="text-2xl font-bold text-main">
              {user?.overallBalance}
            </span>
          </div>
        </div>
        <div className="mt-5 flex flex-col md:flex-row md:mt-0">
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Passive income earned:
            </h3>
            <p className="font-bold text-lGrey">${user?.passiveIncome}</p>
          </div>
          <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Total referral bonuses:
            </h3>
            <p className="font-bold text-lGrey">${user?.referralBonuses}</p>
          </div>

          <>
            <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
            <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
              <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
                Profit available for claim:
              </h3>
              <p className="font-bold text-lGrey">${availableForWithdraw}</p>
              <Button
                type="button"
                className="bg-main  mt-5 font-semibold"
                onClick={handleWithdrawProfitFromContract}
                isLoading={isLoadingClaimingBinaryBonus}
                disabled={+availableForWithdraw <= 0}
              >
                Claim Profit
              </Button>
              <div className="flex items-center mt-3">
                <p className="text-[8px] font-semibold text-grey mr-[6px]">
                  Withdrawal fee 10%
                </p>
                <InfoIcon />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
