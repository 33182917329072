import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router";
import { authWithWallet } from "requests";
import { Auth } from "types";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";

import { ConnectKitButton } from "connectkit";

export const SocialAuth = () => {
  const { address } = useAccount();
  const { data: signMessageData, signMessage } = useSignMessage();

  // const { sdk } = useSDK();
  const location = useLocation();
  const referralCode = location.search.includes("?ref")
    ? location.search.replace("?ref=", "")
    : null;

  const { mutate } = useMutation({
    mutationFn: authWithWallet,
    onSuccess: (data) => {
      // const status = data.status;
      const _data = data.data;

      Cookies.set(Auth.TOKEN, _data.token);
      Cookies.set(Auth.REFRESH, _data.refreshToken);

      // if (status === 200) {
      //   Cookies.set(Auth.TOKEN, _data.token);
      //   Cookies.set(Auth.REFRESH, _data.refresh_token);
      // } else if (status === 201) {
      //   navigate(`/auth/sign-up/step-one?token=${_data.token}`);
      //   toast.success("Success");
      // }

      // queryClient.invalidateQueries("recentSessions", { exact: true });

      // // if no 2fa is enabled on account

      window.location.reload();
    },
    onError: () => {
      console.log("error?");
      toast.error("Error while connecting");
    },
  });

  // const handleLoginWithWallet = async (address: string) => {
  //   setIsLoading(true);
  //   try {
  //     mutate({
  //       tronAddress: address,
  //       signature: signMessageData,
  //     });

  //     // !referralCode
  //     //   ? mutate({
  //     //       tronAddress: address,
  //     //       signature: signMessageData,
  //     //     })
  //     //   : mutate({
  //     //       tronAddress: address,
  //     //       signature: signMessageData,
  //     //       referralCode,
  //     //     });
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("Failed to connect");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    if (address && !signMessageData) {
      signMessage({
        message: `Connect ${address} to Smart-X`,
      });
    }
  }, [address]);

  useEffect(() => {
    if (signMessageData && address) {
      !referralCode
        ? mutate({
            address: address,
            signature: signMessageData,
          })
        : mutate({
            address: address,
            signature: signMessageData,
            referralCode,
          });
    }
  }, [signMessageData, address]);

  return (
    <>
      <ConnectKitButton />
    </>
  );
};
