import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { depositFormValidation, isDevConfig } from "const";

import { Input } from "components/shared/Input";
import { Button } from "components/shared/Button";

import { useUserContext } from "context/user.context";
import { Box } from "components/shared/Box";
import { useState } from "react";
import { useAccount } from "wagmi";

export const DepositForm = () => {
  const { depositFunds } = useUserContext();
  const { chainId } = useAccount();

  const [isDepositLoading, setIsDepositLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      amount: undefined,
    },
    resolver: zodResolver(depositFormValidation),
    reValidateMode: "onSubmit",
  });

  const onSubmit = (formValues: any) => {
    setIsDepositLoading(true);
    depositFunds(formValues.amount)
      .then(() => {
        reset();
      })
      .finally(() => {
        setIsDepositLoading(false);
      });
  };

  const renderDepForm = () => {
    if (!chainId) return null;

    if (!isDevConfig && chainId !== 56)
      return (
        <p className="text-main text-center text-2xl">
          Please, select BNB Smart Chain
        </p>
      );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="number"
          id="amount"
          register={register}
          placeholder="Amount"
          errorMessage={errors.amount?.message as string}
          label="Amount"
        />
        <Button
          type="submit"
          className="bg-main mt-4 font-semibold text-black"
          isLoading={isDepositLoading}
        >
          Deposit crypto
        </Button>
      </form>
    );
  };

  return (
    <div>
      <div className="flex items-center mb-5">
        <h4 className="text-lGrey text-base">Deposit cryptocurrency</h4>
      </div>
      <Box className="bg-lightBlack">{renderDepForm()}</Box>
    </div>
  );
};
