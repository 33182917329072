import classNames from "classnames";
import { useToggle } from "hooks";
import { FC, cloneElement } from "react";
import { ChevronDownAccordion, CloseIconFaq } from "../Icons";

interface AccordionProps {
  title: string;
  text: any;
}

export const Accordion: FC<AccordionProps> = ({ title, text }) => {
  const [showContent, toggleContent] = useToggle();

  return (
    <div
      className={classNames(
        "bg-lightBlack border rounded-md",
        !showContent ? "border-[#2C3138]" : "border-[#FEE30A]"
      )}
    >
      <div
        onClick={toggleContent}
        className="flex items-center py-4 px-6 cursor-pointer"
      >
        <p
          className={classNames(
            showContent ? "text-white" : "text-[#AAAAAA]",
            "text-sm font-bold"
          )}
        >
          {title}
        </p>
        <div className="ml-auto">
          {showContent ? (
            <CloseIconFaq />
          ) : (
            <div className="p-1">
              {" "}
              <ChevronDownAccordion />{" "}
            </div>
          )}
        </div>
      </div>
      {showContent && (
        <div className="py-4 px-6 pt-0 text-white">{cloneElement(text)}</div>
      )}
    </div>
  );
};
